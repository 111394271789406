<template>
  <div :class="selectedClass + 'hour'" @click="selectHour">
    <p class="sm-text">
      {{ data }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Hours",
  props: {
    data: { type: String, default: "" },
    isSelected: { type: Boolean, default: false },
  },
  computed: {
    selectedClass() {
      return this.isSelected ? "selected " : "";
    },
  },
  methods: {
    selectHour() {
      this.$emit("selectHour", this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Calendar";
</style>
