<template>
  <div :class="classButton + 'svg-arrow-container'" @click="action()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.4"
      height="16.1"
      viewBox="0 0 9.4 6.1"
    >
      <path
        d="M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z"
        transform="translate(-2 -2)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classButton: this.isButton ? "btn-icon " : "",
    };
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Icons";
</style>
