<template>
  <div class="calendar-date-selector row">
    <DropDownArrowIcon
      :isButton="true"
      @action="selectPrevious"
      :class="isCurrentMonth ? 'disabled previous' : 'previous'"
    />
    <p class="text">{{ selectedMonth }}</p>
    <DropDownArrowIcon
      :isButton="true"
      @action="selectNext"
      :class="isPast2Month ? 'disabled next' : 'next'"
    />
  </div>
</template>

<script>
import DropDownArrowIcon from "@/assets/icons/DropDownArrowIcon.vue";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/pt";

import { country } from "@/services/country";

export default {
  name: "CalendarHeader",
  components: {
    DropDownArrowIcon,
  },
  props: {
    selectedDate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCurrentMonth: true,
      isPast2Month: false,

      country,
    };
  },
  computed: {
    selectedMonth() {
      return this.selectedDate.locale(country).format("MMMM YYYY");
    },
  },
  methods: {
    selectPrevious() {
      const newSelectedDate = dayjs(this.selectedDate).subtract(1, "month");
      this.$emit("dateSelected", newSelectedDate);
      this.checkIfCurrentMonth(newSelectedDate);
      this.checkIfPast2Month(newSelectedDate);
    },
    selectNext() {
      let newSelectedDate = dayjs(this.selectedDate).add(1, "month");
      this.$emit("dateSelected", newSelectedDate);
      this.checkIfCurrentMonth(newSelectedDate);
      this.checkIfPast2Month(newSelectedDate);
    },
    checkIfCurrentMonth(date) {
      if (dayjs(date).subtract(1, "month").isBefore(dayjs().startOf("month"))) {
        this.isCurrentMonth = true;
      } else {
        this.isCurrentMonth = false;
      }
    },
    checkIfPast2Month(date) {
      if (dayjs(date).isAfter(dayjs().startOf("month").add(2, "month"))) {
        this.isPast2Month = true;
      } else {
        this.isPast2Month = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Calendar";
</style>
