<template>
  <div>
    <div class="hours-container row">
      <div>
        <Hours
          v-for="(hour, index) in morning.slice(0, 4)"
          :key="index"
          :data="hour"
          @selectHour="selectHour"
          :isSelected="hour == selectedHour"
        />
      </div>
      <div>
        <Hours
          v-for="(hour, index) in afternoon.slice(0, 4)"
          :key="index"
          :data="hour"
          @selectHour="selectHour"
          :isSelected="hour == selectedHour"
        />
      </div>
    </div>
    <div class="hour-select">
      <select name="hours" @change="selectHour($event.target.value)">
        <option value="">{{ translateString("selectOtherHour") }}</option>
        <option
          v-for="(hour, index) in data"
          :key="index"
          :value="hour"
          :selected="hour == selectedHour"
        >
          {{ hour }}
        </option>
      </select>
      <DropDownArrowIcon :isButton="true" class="icon-absolute" />
    </div>
    <br>
  </div>
</template>

<script>
import DropDownArrowIcon from "@/assets/icons/DropDownArrowIcon.vue";
import Hours from "@/components/Calendar/Hours.vue";

import { translateString } from "@/functions/functions.js";

export default {
  name: "HoursContainer",
  components: {
    DropDownArrowIcon,
    Hours,
  },
  props: {
    data: { type: Array },
    morning: { type: Array },
    afternoon: { type: Array },
  },
  computed: {
    selectedHour() {
      return this.$store.state.availabilitySelected.hour;
    },
  },
  methods: {
    translateString,
    selectHour(event) {
      this.$emit("action", event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Calendar";
</style>
